import React from "react";
import Layout from "../layouts/Layout";

export default () => {
  return (
    <Layout>
      <h1>Désolé, aucune fuite détectée à cet endroit...</h1>
    </Layout>
  );
};
